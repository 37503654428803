import styled from 'styled-components';

export default styled.div`
  .ant-form label {
    font-size: 0.75rem;
    font-family: Source Sans Pro, sans-serif;
    color: #606f7b;
    text-transform: uppercase;
    label::after {
      content: none;
    }
  }

  .ant-form-explain {
    font-size: 0.75rem;
    font-weight: light;
  }

  .ant-form-item-label {
    label::after {
      content: none;
    }
    display: inline-block;
    overflow: hidden;
    line-height: 1.5em;
    white-space: inherit;
    text-align: left;
    vertical-align: middle;
  }

  .ant-select-selection-selected-value {
    width: 100%;
  }

  .ant-select-selection,
  .ant-input,
  .ant-input:focus,
  .has-error .ant-input:focus,
  .ant-select ant-select-enabled ant-select-no-arrow,
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active,
  .has-error .ant-select-open .ant-select-selection,
  .has-error .ant-select-focused .ant-select-selection {
    border-right: 0;
    border-left: 0;
    border-top: 0;
    box-shadow: none;
  }

  .has-error .ant-input {
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid ${props => props.theme.dangerColor};
    border-top: 0;
    box-shadow: none;
  }

  .has-error .ant-input-affix-wrapper .ant-input:focus,
  .has-error .ant-select-open .ant-select-selection {
    box-shadow: none;
    border-bottom: 2px solid ${props => props.theme.dangerColor};
  }

  .ant-input-affix-wrapper .ant-input:focus,
  .ant-select-open .ant-select-selection {
    box-shadow: none;
    border-bottom: 2px solid ${props => props.theme.primaryColor};
  }

  .ant-input:focus {
    border-bottom: 2px solid ${props => props.theme.primaryColor};
  }

  .ant-btn {
    text-transform: capitalize;
    border-radius: 1px;
  }

  .ant-tag {
    border-radius: 2px;
  }

  .ant-tooltip {
    border-radius: 2px;
  }

  textarea.ant-input {
    border-right: 1px solid #dae1e7 !important;
    border-left: 1px solid #dae1e7 !important;
    border-top: 1px solid #dae1e7 !important;
  }

  .ant-select {
    width: 100%;
  }

  // Ant Table Overrides
  .ant-table-thead > tr > th {
    border-bottom: 0;
  }

  .ant-table-thead > tr > th .ant-table-column-sorter-up.on,
  .ant-table-thead > tr > th .ant-table-column-sorter-down.on {
    color: hsl(208, 100%, 70%);
  }

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
    .ant-table-filter-icon:hover,
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-filters
    .anticon-filter.ant-table-filter-open,
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-filters
    .ant-table-filter-icon.ant-table-filter-open {
    color: hsl(208, 100%, 70%);
  }

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
    .ant-table-filter-icon:active {
    color: hsl(208, 100%, 70%);
  }

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover {
    color: hsl(208, 100%, 70%);
  }

  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-filters
    .anticon-filter.ant-table-filter-open {
    color: hsl(208, 100%, 70%);
  }
  .ant-table-filter-dropdown-link,
  .ant-table-filter-dropdown-link.confirm,
  .ant-table-filter-dropdown-link.reset {
    color: #2d84c1 !important;

    a {
      color: hsl(208, 100%, 70%);
    }
    &:hover {
      color: hsl(208, 100%, 70%);
    }
  }

  .ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
    color: hsl(208, 100%, 70%);
  }

  .w-100 {
    width: 100%;
  }
  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }

  .align-items-center {
    align-items: center;
  }

  @media print {
    .layoutContainer > * {
      display: none !important;
    }

    .layoutContainer .flex.justify-center.font-source-sans-pro {
      display: block !important;
    }

    a {
      display: none;
    }

    button {
      display: none;
    }

    .ant-row-flex {
      padding: 2px !important;
    }

    .ant-card-body {
      padding: 10px !important;
    }

    * {
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact !important;
    }
  }
`;
